import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FeedIcon from '@mui/icons-material/Feed';
import HomeIcon from '@mui/icons-material/Home';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { styled } from '@mui/material/styles';
const categories = [
  { name: 'พระสมเด็จ', path: '/phrasomdej' },
  { name: 'หลวงปู่ทวด', path: '/luangputhuat' },
  { name: 'หลวงพ่อเงิน', path: '/luangphongoen' },
  { name: 'พระเครื่องอื่นๆ', path: '/etc' },
];



const drawerWidth = 240; // Set drawer width

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCategoryClick = () => {
    setOpenCategory(!openCategory);
  };

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'white',
  }));
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar at the top */}
      <AppBar position="fixed" sx={{ width: '100%', zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <StyledLink to="/">
      <Typography variant="h6" component="div">
        Yothinkrabi
      </Typography>
    </StyledLink>
            <Box width='100%' sx={{marginLeft:2}}>
            {/* <SearchProduct /> */}
            </Box>
          </Box>

          {isMobile ? (
            <>
              {/* Replace buttons with icons in mobile mode */}
              <IconButton color="inherit" component={Link} to="/">
                <HomeIcon />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/certificate">
                <CardMembershipIcon />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/news">
                <FeedIcon />
              </IconButton>
            </>
          ) : (
            <>
              {/* Full buttons for non-mobile mode */}
              <Button color="inherit" component={Link} to="/">หน้าหลัก</Button>
              <Button color="inherit" component={Link} to="/news">ประชาสัมพันธ์</Button>
              {/* <Button color="inherit" component={Link} to="/certificate">ใบรับรองพระ</Button> */}
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer on the side for mobile view */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: isMobile ? '56px' : '64px',
          },
        }}
      >
        <List>
    
          <ListItemButton 
                           component={Link}
                           to={'/'}
                           >
            <ListItemText primary="รายการพระทั้งหมด"/>
          </ListItemButton>
          {/* <ListItemButton >
            <ListItemText primary="พระอัพเดท" />
          </ListItemButton> */}
          {/* <ListItemButton >
            <ListItemText primary="ใบรับรองพระ" />
          </ListItemButton> */}
          <ListItemButton onClick={handleCategoryClick}>
            <ListItemText primary="หมวดหมู่พระ" />
            {openCategory ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCategory} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {categories.map((category) => (
                <ListItemButton
                  key={category.name}
                  component={Link}
                  to={category.path}
                  onClick={() => setOpen(false)}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary={category.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;
