import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from './components/Navbar';
import AdminNavbar from './components/AdminNavbar';

const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const appBarHeight = isMobile ? 56 : 64; // Adjust height based on screen size
  const adminRoutes = ['/admin', '/news-admin']; // Admin routes
  const noNavbarRoutes = ['/login']; // Routes that don't require a Navbar

  // Determine if the current route is an admin route
  const isAdminRoute = adminRoutes.some(route => location.pathname.startsWith(route));

  // Determine if the current route should hide the Navbar
  const isNoNavbarRoute = noNavbarRoutes.some(route => location.pathname.startsWith(route));

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Conditionally render Navbar based on the current route */}
      {!isNoNavbarRoute && (isAdminRoute ? <AdminNavbar /> : <Navbar />)}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          mt: `${!isNoNavbarRoute ? appBarHeight : 0}px`, // Adjust margin-top if Navbar is present
          width: `calc(100% - 240px)`,
        }}
      >
        {/* Outlet will render the child routes */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
