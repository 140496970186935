import * as React from 'react';
import {  Grid, Container, Typography, CardMedia, Card, Box, CardContent, Button, Paper, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from 'react';

import CardActions from '@mui/material/CardActions';


const TruncatedTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 3,
  whiteSpace: 'normal',
  wordBreak: 'break-word', 
});


const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'No Date';

  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Options for Thai date formatting
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'Asia/Bangkok',
  };

  // Format the date
  const formatter = new Intl.DateTimeFormat('th-TH', options);
  const [day, month, year] = formatter.format(date).split('/');

  // Return formatted date as วัน/เดือน/ปี
  return `${day}/${month}/${year}`;
};


// Styled Paper component for blog posts
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    boxSizing: 'border-box', 
    [theme.breakpoints.up('xs')]: {
      width: '100%', // Full width on small screens
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px', // Set width for small screens and up
    },
    [theme.breakpoints.up('md')]: {
      width: '800px', // Set width for medium screens and up
    },
    [theme.breakpoints.up('lg')]: {
      width: '1000px', // Set width for large screens and up
    },
  }));

const NewsPage = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedPost, setSelectedPost] = useState(null);
    const [blogPosts, setBlogPosts] = useState([]);

    const handleClickOpen = (post) => {
        setSelectedPost(post);
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
        setSelectedPost(null);
    };

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await fetch('/api/announcements');
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setBlogPosts(data);
          } catch (error) {
              console.log(error.message);
          } finally {
              setLoading(false);
          }
      };
      fetchData();
  }, []);

  
  return (
    <Container>
    <Paper style={{ padding: 16 }}>
      <Typography variant="h1" gutterBottom>
        ประชาสัมพันธ์
      </Typography>
      <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Grid container spacing={2} justifyContent="center">
        {blogPosts.map((post, index) => (
        <Grid item xs={12} sm={6} md={12} key={index}>
               <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledPaper key={index}  
          sx={{
            padding: 3,
            textAlign: 'left',
            color: (theme) => theme.palette.text.secondary,
            marginBottom: 2,
            maxWidth: { xs: '500px', md: '1000px' }, 
            boxSizing: 'border-box', 
          }}>
           <Card>
              {/* ใช้ Box สำหรับจัดเลย์เอาต์ในแนวนอน */}
              <Box  display="flex" 
                    flexDirection={{ xs: 'column', md: 'row' }}
                    alignItems="flex-start">
           
           <Box
              sx={{
                flex: '0 0 400px', // กำหนดขนาดคงที่ของ Box รูปภาพเป็น 300px
                display: 'flex',
                justifyContent: 'center', // จัดแนวกลางในแนวนอน
                alignItems: 'center',     // จัดแนวกลางในแนวตั้ง
                overflow: 'hidden',
              }}
            >
                    <CardMedia
                        component="img"
                        image={post?.image_path}
                        alt={post?.title}
                        sx={{
                        maxWidth: '100%', // ให้ความกว้างสูงสุดของรูปภาพปรับตามขนาดคอมโพเนนต์
                        height: 'auto',  // ความสูงจะปรับตามอัตราส่วนของรูปภาพ
                        objectFit: 'cover', // ป้องกันการบิดเบี้ยวของรูปภาพ
                        }}
                    />
                    </Box>


                    <Box
      sx={{
        flex: '1 1 auto', // ให้ Box เนื้อหาหดหรือขยายตามพื้นที่ที่เหลือ
        padding: 2,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {post?.title}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
        {formatTimestamp(post?.time_stamp)}
        </Typography>
        <TruncatedTypography variant="body2" color="text.secondary">
          {post?.detail}
        </TruncatedTypography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={() => handleClickOpen(post)}
        >
          Read More
        </Button>
      </CardContent>
    </Box>
              </Box>
            </Card>
          </StyledPaper>
          </Box>
        </Grid>
        ))}
      </Grid>
    </Container>

    </Paper>

    <Dialog open={open} onClose={handleClose}>
                <Card   sx={{ 
                          maxWidth: '100%', // Ensure card does not exceed its container width
                          maxHeight: '100rem', // Set a maximum height for the card
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                 <CardMedia
                        component="img"
                        sx={{ 
                          width: '100%',  // Make sure the image fills the width of the card
                          height: 'auto', // Maintain the aspect ratio of the image
                          objectFit: 'cover' // Cover the area without stretching
                        }}
                        image={selectedPost ? selectedPost.image_path : null}
                        title="Announcement Image"
                        alt={selectedPost ? selectedPost.title : 'Announcement'}
                      />
                <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="div" 
                    sx={{ padding: '10px' }}
                  >
                    {selectedPost ? selectedPost.title : 'No Title'}
                  </Typography>

                    <CardContent
                    sx={{
                      height: '300px',
                       overflow: 'auto'
                    }}
                    >
                  <Typography 
                      variant="body2" 
                      sx={{ 
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word', 
                      }}
                    >
                      {selectedPost ? selectedPost.detail : 'No Details'}
                    </Typography>
                    </CardContent>
                  
                </Card>
            </Dialog>

  </Container>
   
  );
};

export default NewsPage;
