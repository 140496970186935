import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TextField, Grid } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import axios from 'axios'
import Swal from 'sweetalert2';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    '& .MuiDialog-paper': {
      width: '80%', 
      maxWidth: '1000px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      width: '90%',
      maxWidth: '600px',
    },
  },
}));

const swalStyle = document.createElement('style');
swalStyle.innerHTML = `
  .swal2-container {
    z-index: 2000 !important;
  }
`;
document.head.appendChild(swalStyle);

const CarouselContainer = styled('div')(({ theme }) => ({
  '& .carousel .thumbs-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  '& .carousel .thumb': {
    margin: '0 2px',
  },
}));

const VisuallyHiddenInput = styled('input')(({ theme }) => ({
  display: 'none',
}));

export default function CustomizedDialogs({ openDialog, onClose, product }) {
  const [item_name, setItem_Name] = React.useState(product?.item_name || '');
  const [description, setDescription] = React.useState(product?.description || '');
  const [price, setPrice] = React.useState(product?.price || '');
  const [contact_line, setContact_Line] = React.useState(product?.contact_line || '');
  const [contact_Phone, setContact_Phone] = React.useState(product?.contact_Phone || '');
  const [catalog_type, setCatalog_Type] = React.useState(product?.catalog_type || '');
  const [state_type, setState_Type] = React.useState(product?.state_type || '');
  const [sku_code,setSku_Code] = React.useState(product?.sku_code || '')
  const [files, setFiles] = React.useState([]);
  const [super_hit, setSuper_Hit] = React.useState(product?.super_hit || false);


  const [previews, setPreviews] = React.useState([]);

  const [showErrors, setShowErrors] = React.useState(false);

  // Handle existing product images
  React.useEffect(() => {
    if (product?.images) {
      const initialFiles = product.images.map(image => ({
        file: null,
        preview: image.image_path
      }));
      setFiles(initialFiles);
      setPreviews(initialFiles.map(file => file.preview));
    }
  }, [product]);


  const handleClose = () => {
    // Reset form fields to initial values from product
    setItem_Name(product?.item_name || '');
    setDescription(product?.description || '');
    setPrice(product?.price || '');
    setContact_Line(product?.contact_line || '');
    setContact_Phone(product?.contact_Phone || '');
    setCatalog_Type(product?.catalog_type || '');
    setState_Type(product?.state_type || '');

    // Reset files and previews
    const initialFiles = product?.images?.map(image => ({
      file: null,
      preview: image.image_path
    })) || [];
    setFiles(initialFiles);
    setPreviews(initialFiles.map(file => file.preview));

    onClose();
  };

  // Handle new file uploads and generate previews
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));

    setFiles(prevFiles => [...prevFiles, ...newFiles.map(file => ({ file, preview: URL.createObjectURL(file) }))]);
    setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
  };

  // Handle file deletion
  const handleFileDelete = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
  };

  const fetchFileFromUrl = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const blob = await response.blob();
      const filename = url.split('/').pop(); // Use the last part of the URL as the filename
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error('Failed to fetch file from URL:', error);
      return null;
    }
  };

  const handleTypeChange = (event) => {
    setCatalog_Type(event.target.value);
  };

  const handleProductStateChange = (event) => {
    setState_Type(event.target.value);
  };


  const validateForm = () => {
    let isValid = true;
  
    if (!item_name || !description || !sku_code || !catalog_type || !state_type) {
      setShowErrors(true);
      isValid = false;
    } else {
      setShowErrors(false); // Hide errors if all required fields are filled
    }
  
    if (price && isNaN(price)) {
      Swal.fire({
        title: 'ข้อมูลราคาไม่ถูกต้อง',
        text: 'กรุณากรอกเฉพาะตัวเลขในช่องราคา',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      });
      isValid = false;
    }
  
    if (contact_Phone && !/^[0]\d{9}$/.test(contact_Phone)) {
      Swal.fire({
        title: 'เบอร์โทรไม่ถูกต้อง',
        text: 'กรุณากรอกเบอร์โทรที่ขึ้นต้นด้วย 0 และมี 10 หลัก',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      });
      isValid = false;
    }
  
    return isValid;
  };


  const handleSubmit = async () => {

    if (!validateForm()) {
      // Stop the submission if the form is invalid
      return;
    }

    const hasMissingInfo = !price || !contact_line || !contact_Phone || files.length === 0;

    if (hasMissingInfo) {
      let errorMessage = 'ท่านยังไม่กรอกข้อมูลเหล่านี้:';
      if (!price) errorMessage += '\n- ราคา';
      if (!contact_line) errorMessage += '\n- Line ID';
      if (!contact_Phone) errorMessage += '\n- เบอร์โทร';
      if (files.length === 0) errorMessage += '\n- รูปภาพ';

  
      Swal.fire({
        title: 'ข้อมูลไม่ครบ',
        text: errorMessage + '\n ยืนยันที่จะบันทึกข้อมูลใช่หรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await submitForm();
        }
      });
      return;

    }

    Swal.fire({
      title: 'ยืนยันข้อมูล ?',
      text: 'ยืนยันที่จะบันทึกข้อมูลลงฐานข้อมูลใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await submitForm();
      }
    });
    return

  };


  const submitForm = async () => {

    
    const formData = new FormData();
    formData.append('inventory_id', product?.inventory_id);
    formData.append('item_name', item_name);
    formData.append('description', description);
    formData.append('sku_code', sku_code);
    formData.append('price', price);
    formData.append('contact_line', contact_line);
    formData.append('contact_phone', contact_Phone);
    formData.append('catalog_type', catalog_type);
    formData.append('state_type', state_type);
    formData.append('super_hit', super_hit);

    
    const filePromises = files.map(async ({ file, preview }) => {
      if (file) return file;
      return await fetchFileFromUrl(preview);
    });
  
    const fileObjects = await Promise.all(filePromises);
  
    fileObjects.forEach(file => {
      if (file) {
        formData.append('pictures', file);
      }
    });
  
    const productinventory_id = product?.inventory_id;
    const token = localStorage.getItem('authToken');

    
    try {

      Swal.fire({
        title: 'กรุณารอสักครู่',
        text: 'กำลังบันทึกข้อมูล...',
        allowOutsideClick: false, // Prevent closing the alert
        didOpen: () => {
          Swal.showLoading();
        }
      });


      await axios.put(`/api/inventory/${productinventory_id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      Swal.fire({
        title: 'สำเร็จ',
        text: 'ข้อมูลของคุณได้ถูกส่งเรียบร้อยแล้ว',
        icon: 'success',
        confirmButtonText: 'ตกลง'
      }).then((result) => {
        if (result.isConfirmed) {

          onClose(true);
        }
      });

    } catch (error) {
      console.error('Update failed:', error);

      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้ โปรดลองอีกครั้ง',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      });

    }
 
  };

  

  

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems={'center'}>
            {/* Carousel Section */}
            <Grid item xs={12} md={7}>
              <CarouselContainer>
                <Carousel showThumbs={true} showStatus={false} infiniteLoop={true}>
                  {previews.length ? (
                    previews.map((preview, index) => (
                      <div key={index}>
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          style={{
                            maxWidth: '500px',
                            height: 'auto',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <Typography variant="h6">ไม่มีรูปที่อัปโหลด</Typography>
                    </div>
                  )}
                </Carousel>
              </CarouselContainer>
            </Grid>

            {/* Form Section */}
            <Grid item xs={12} md={5}>
              <Box>
             <TextField
                  required
                  label="ชื่อสินค้า"
                  value={item_name}
                  onChange={(e) => setItem_Name(e.target.value)}
                  error={!item_name && showErrors}
                  helperText={!item_name && showErrors ? "กรุณากรอกชื่อสินค้า" : ""}
             
                />

                <Box sx={{ borderBottom: '2px solid #ccc', my: 2 }} />
                <FormControl fullWidth>
                <TextField
                  required
                  label="รหัส SKU"
                  value={sku_code}
                  onChange={(e) => setSku_Code(e.target.value)}
                  error={!sku_code && showErrors}
                  helperText={!sku_code && showErrors ? 'กรุณากรอกรหัส SKU' : ''}
                  sx={{ marginBottom: '16px' }}
                />
              </FormControl>


            <FormControl fullWidth>
                    <TextField
                      required
                      label="รายละเอียด"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      multiline
                      rows={4} 
                      error={!description && showErrors}
                      helperText={!description && showErrors ? 'กรุณากรอกรายละเอียด' : ''}
                      sx={{ marginBottom: '16px' }}
                    />
                  </FormControl>

                <TextField
                  variant="outlined"
                  label="ราคา"
                  fullWidth
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  sx={{ marginBottom: '16px' }}
                />

                <TextField
                  variant="outlined"
                  label="Line ID"
                  fullWidth
                  value={contact_line}
                  onChange={(e) => setContact_Line(e.target.value)}
                  sx={{ marginBottom: '16px' }}
                />

                <TextField
                  variant="outlined"
                  label="เบอร์ติดต่อ"
                  fullWidth
                  value={contact_Phone}
                  onChange={(e) => setContact_Phone(e.target.value)}
                  sx={{ marginBottom: '16px' }}
                />

                <FormControl fullWidth sx={{ marginBottom: '16px' }} error={!catalog_type && showErrors}>
                  <InputLabel>ประเภทพระ</InputLabel>
                  <Select
                    value={catalog_type}
                    onChange={handleTypeChange}
                    label="ประเภทพระ"
                  >
                   {/* Empty value for default */}
                    <MenuItem value="พระสมเด็จ">พระสมเด็จ</MenuItem>
                    <MenuItem value="หลวงปู่ทวด">หลวงปู่ทวด</MenuItem>
                    <MenuItem value="หลวงพ่อเงิน">หลวงพ่อเงิน</MenuItem>
                    <MenuItem value="พระเครื่องอื่นๆ">พระเครื่องอื่นๆ</MenuItem>
                  </Select>
                  {!catalog_type && showErrors && <Typography color="error">กรุณาเลือกประเภทพระ</Typography>}
                </FormControl>


                <FormControl fullWidth sx={{ marginBottom: '16px' }} error={!state_type && showErrors}>
                  <InputLabel>สถานะสินค้า</InputLabel>
                  <Select
                    value={state_type}
                    onChange={handleProductStateChange}
                    label="สถานะสินค้า"
                  >
                   {/* Empty value for default */}
                    <MenuItem value="new">มาใหม่</MenuItem>
                    <MenuItem value="show">โชว์พระ</MenuItem>
                    <MenuItem value="sale">เปิดขาย</MenuItem>
                    <MenuItem value="sold">ขายแล้ว</MenuItem>
                  </Select>
                  {!state_type && showErrors && <Typography color="error">กรุณาเลือกสถานะสินค้า</Typography>}
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                  <Typography component="div">สินค้าเด่น</Typography>
                  <Switch
                    checked={super_hit}
                    onChange={(e) => setSuper_Hit(e.target.checked)}
                    inputProps={{ 'aria-label': 'super hit switch' }}
                  />
                </FormControl>


              </Box>
            </Grid>

            {/* File Upload Section */}
            <Grid item xs={12}>
              <Box>
                <label htmlFor="file-upload">
                  <VisuallyHiddenInput
                    accept="image/*"
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<AddPhotoAlternateIcon />}
                  >
                    อัปโหลดรูปภาพ
                  </Button>
                </label>
              </Box>
              {files.length > 0 && (
                <Box mt={2}>
                  <Typography variant="h6">ไฟล์ที่อัปโหลด</Typography>
                  <Grid container spacing={2}>
                    {files.map((file, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <Box position="relative">
                          <img
                            src={file.preview}
                            alt={`File ${index}`}
                            style={{
                              maxWidth: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                            }}
                          />
                          <IconButton
                            onClick={() => handleFileDelete(index)}
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              color: 'red',
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit}>
            บันทึก
          </Button>
          <Button onClick={handleClose}>ยกเลิก</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
