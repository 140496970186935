import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FeedIcon from '@mui/icons-material/Feed';
import InventoryIcon from '@mui/icons-material/Inventory';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
const categories = [
  { name: 'พระสมเด็จ', path: '/phrasomdej' },
  { name: 'หลวงปู่ทวด', path: '/luangputhuat' },
  { name: 'หลวงพ่อเงิน', path: '/luangphongoen' },
  { name: 'พระเครื่องอื่นๆ', path: '/etc' },
];

const drawerWidth = 240; // Set drawer width

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCategoryClick = () => {
    setOpenCategory(!openCategory);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar at the top */}
      <AppBar position="fixed" sx={{ width: '100%', zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6" component="div">
              Yothinkrabi
            </Typography>
            <Box width='100%' sx={{marginLeft:2}}>
            {/* <SearchProduct /> */}
            </Box>
          </Box>

          {isMobile ? (
            <>
              {/* Replace buttons with icons in mobile mode */}
              <IconButton color="inherit" component={Link} to="/admin">
                <InventoryIcon />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/news-admin">
                <FeedIcon />
              </IconButton>
            </>
          ) : (
            <>
              {/* Full buttons for non-mobile mode */}
              <Button color="inherit" component={Link} to="/admin">จัดการสินค้า</Button>
              <Button color="inherit" component={Link} to="/news-admin">จัดการประชาสัมพันธ์</Button>
              {/* <Button color="inherit" component={Link} to="/certificate">ใบรับรองพระ</Button> */}
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer on the side for mobile view */}

    </Box>
  );
};

export default Navbar;
