// Poster.tsx
import * as React from 'react';
import Box from '@mui/material/Box';

const Poster = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 400,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0', // สีพื้นหลังเมื่อภาพโหลดช้า
      }}
    >
      <img
        src="/images/background.jpg" // เปลี่ยน path ให้ตรงกับที่เก็บไฟล์
        alt="Poster"
        style={{
          width: '100%',
          height: 'auto', // ให้ความสูงของภาพคงที่ และให้ความกว้างเต็มที่
          objectFit: 'cover', // ทำให้ภาพครอบคลุมพื้นที่ที่กำหนด
        }}
      />
    </Box>
  );
};

export default Poster;
