import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

import Poster from '../components/Poster'

const HomePage = () => {
  return (
    <div>
      <Poster/>
      <Container>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h1" gutterBottom>
            404 Not Found
          </Typography>
    
        </Paper>
      </Container>
    </div>
  );
};

export default HomePage;
