import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardMedia, Box, IconButton, CardContent, Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Swal from 'sweetalert2';

const NewAnnouncementDialog = ({ open, onClose, onRefresh }) => {
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({ title: '', detail: '' });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) errors.title = 'Title is required';
    if (!detail.trim()) errors.detail = 'Detail is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('detail', detail);
    if (image) {
      formData.append('image_path', image);
    }

    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to save this announcement?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Saving...',
            text: 'Please wait while we save your announcement.',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            }
          });

          const response = await fetch('/api/announcements', {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            Swal.fire('Saved!', 'Announcement created successfully.', 'success');
            onClose();
            onRefresh();  // Close dialog after successful save
          } else {
            Swal.fire('Error!', 'There was a problem creating the announcement.', 'error');
          }
        }
      });
    } catch (error) {
      console.error('Error:', error);
      Swal.fire('Error!', 'An error occurred while saving.', 'error');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create New Announcement</DialogTitle>
      <DialogContent>
        <Card>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            {/* Preview Image */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                maxWidth: '300px',
                mb: 2,
              }}
            >
              <CardMedia
                component="img"
                alt="Preview"
                image={preview || '/images/placeholder.png'}
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                }}
              />
            </Box>

            {/* Form Inputs */}
            <Box sx={{ padding: 2, flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={!!errors.title}
                  helperText={errors.title}
                />
                <TextField
                  label="Detail"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                  error={!!errors.detail}
                  helperText={errors.detail}
                />

                {/* Upload Image */}
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="upload-image"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="upload-image">
                  <IconButton color="primary" component="span">
                    <PhotoCamera />
                  </IconButton>
                  Upload Image
                </label>
              </CardContent>
            </Box>
          </Box>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined' >
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant='contained' color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewAnnouncementDialog;
