import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#424242', 
    },
    secondary: {
      main: '#F8E559', 
    },
    newitem: {
      main: '#2c387e', 
    },
    showitem: {
      main: '#1769aa',
    },
    saleitem:{
      main: '#008394',
    }

  },
  typography: {
    fontFamily: '"Kanit", "Arial", "sans-serif"',
  },
});

export default theme;
