import React, { useState, useEffect, useRef } from 'react';
import ProductCard from './ProductCard';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SearchProduct from './SearchProduct';
import PCAdmin from './PCAdmin';
import AddIcon from '@mui/icons-material/Add';
import AmAddDialog from './AmAddDialog';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';

const PCardGrid = ({ role, products, refreshData }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;
  const totalItems = products.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState('');
  const [maxCardHeight, setMaxCardHeight] = useState(0);
  const cardRefs = useRef([]);

  useEffect(() => {
    // คำนวณความสูงของการ์ดที่ใหญ่ที่สุด
    const cardHeights = cardRefs.current.map(ref => ref?.offsetHeight || 0);
    const largestHeight = Math.max(...cardHeights);
    setMaxCardHeight(largestHeight);
  }, [products, currentPage, searchQuery]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (isSubmitted) => {
    setOpenDialog(false);
    if (isSubmitted) {
      refreshData(); // Refresh data only if submission was successful
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchQueryChange = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); // รีเซ็ตหน้าเป็น 1 เมื่อค้นหา
  };

  const filteredProducts = products.filter(product =>
    product.item_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // คำนวณช่วงสินค้าที่จะแสดงผลในหน้าปัจจุบัน
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // สร้างการ์ดสำหรับสินค้าในหน้าปัจจุบัน
  const renderCards = () => {
    return filteredProducts.slice(startIndex, endIndex).map((product, index) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        key={product.id || index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: maxCardHeight > 0 ? `${maxCardHeight}px` : 'auto',  // ใช้ความสูงการ์ดที่ใหญ่ที่สุด
        }}
        ref={el => cardRefs.current[index] = el} // เก็บ reference ของการ์ด
      >
        {role === 'user' ? (
          <ProductCard product={product} />
        ) : (
          <PCAdmin product={product} refreshData={refreshData} />
        )}
      </Grid>
    ));
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={role === 'admin' ? 8 : 12}>
          {filteredProducts.length > 0 ? (
            <SearchProduct products={products} onSearch={handleSearchQueryChange} />
          ) : null}
        </Grid>
        {role === 'admin' && (
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '100%' }}
              startIcon={<AddIcon />}
              onClick={handleOpenDialog}
            >
              Add Product
            </Button>
            <AmAddDialog openDialog={openDialog} onClose={handleCloseDialog} />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} padding={2}>
        {filteredProducts.length > 0 ? (
          renderCards()
        ) : (
          <Grid item xs={12}>
            <Box textAlign="center" py={5}>
              <h2>ไม่พบสินค้า</h2>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </>
  );
};

export default PCardGrid;
